<template>
  <page-header @back="goBack" title="订单详情"></page-header>
  <!-- 订单信息 -->
  <div class="orderbox">
    <div class="infobox leftbox">
      <el-divider style="margin-top: 0; margin-bottom: 10px">
        <h5>订单信息</h5>
      </el-divider>
      <el-descriptions :column="1" style="margin-left: 15px">
        <el-descriptions-item label="订单编号">{{
            orderData.orderCode
        }}</el-descriptions-item>
        <el-descriptions-item label="下单时间">{{
            orderData.createDate
        }}</el-descriptions-item>
        <el-descriptions-item label="总计金额">{{
            orderData.realMoney + "元"
        }}</el-descriptions-item>
        <el-descriptions-item label="订单状态">{{
            orderData.orderStateVal
        }}</el-descriptions-item>
        <el-descriptions-item label="订单类型">{{
            orderData.orderTypeVal
        }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{
            orderData.note
        }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="infobox rightbox" v-if="orderData.orderType !== 'CLASSORDER'">
      <div class="paybox">
        <el-divider style="margin-top: 0; margin-bottom: 10px">
          <h5>联系人信息</h5>
        </el-divider>
        <el-descriptions :column="1" style="margin-left: 15px">
          <el-descriptions-item label="收货人" v-if="orderData.orderType !== 'CLASSORDER'">{{ orderData.linkman }}
          </el-descriptions-item>
          <el-descriptions-item label="手机号" v-if="orderData.orderType !== 'CLASSORDER'">{{ orderData.cellphone }}
          </el-descriptions-item>
          <el-descriptions-item label="地址" v-if="orderData.orderType !== 'CLASSORDER'">{{ orderData.address }}
          </el-descriptions-item>

          <el-descriptions-item label="团长手机号" v-if="orderData.orderType === 'GROUPORDER'">{{ orderData.managerPhone }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div :class="
      orderData.orderType !== 'CLASSORDER'
        ? 'infocopybox payinfobox'
        : 'infobox payinfobox'
    ">
      <div class="paybox" v-if="orderData.payDate">
        <el-divider style="margin-top: 0; margin-bottom: 10px">
          <h5>支付信息</h5>
        </el-divider>

        <div style="margin-top: 24px" class="rowflex">
          <div style="text-align: left; margin-left: 20px; flex: 1">
            <span>支付平台:</span>
            <span class="marginleft10">微信支付</span>
          </div>
          <div style="text-align: left; margin-left: 20px; flex: 1">
            <span>支付金额:</span>
            <span class="marginleft10">{{ "￥" + orderData.realMoney }}</span>
          </div>
        </div>
      </div>
      <div class="paybox" v-if="orderData.payDate && orderData.cancelDate">
        <el-divider style="margin-top: 0; margin-bottom: 10px">
          <h5>退款信息</h5>
        </el-divider>

        <div style="margin-top: 24px" class="rowflex">
          <div style="text-align: left; margin-left: 20px; flex: 1">
            <span>支付平台:</span>
            <span class="marginleft10">微信支付</span>
          </div>
          <div style="text-align: left; margin-left: 20px; flex: 1">
            <span>退款金额:</span>
            <span class="marginleft10">{{ "￥" + orderData.refundMoney }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <el-empty v-if="goodInfo.length === 0" description="暂无数据"></el-empty>

    <el-table v-else-if="goodInfo.length >= 0" :data="goodInfo" stripe style="width: 100%; margin-top: 30px">
      <el-table-column prop="itemName" label="商品名称" width="180">
      </el-table-column>
      <el-table-column prop="pictureUrl" label="商品图片" width="180">
        <template #default="scope">
          <img v-if="scope.row.pictureUrl" :src="imgBaseUrl + scope.row.pictureUrl"
            style="width: 80px; height: 120px; object-fit: contain" alt="" />
        </template>
      </el-table-column>

      <el-table-column prop="price" label="单价/数量（元）" width="180">
      </el-table-column>
      <el-table-column prop="number" label="数量（件）" width="180">
      </el-table-column>
      <el-table-column prop="money" label="总价（元）" width="180">
      </el-table-column>
      <!-- <el-table-column prop="goodTeamPrice" label="团购价" width="180">
      </el-table-column>
      <el-table-column label="实付款" prop="empty"> </el-table-column> -->
      <!-- <el-table-column prop="address" label="操作">
        <template #default="scope">
          <div>
            <div>
              <el-button
                type="text"
                style="margin-left: 00px !important"
                @click="deletegood(scope.row)"
                >删除</el-button
              >
            </div>
          </div>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>
<script>
import PageHeader from "@/components/common/pageHeader.vue"
export default {
  components: { PageHeader },
  data() {
    return {
      orderData: {},
      goodInfo: []
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '订单管理',
        index: '/order/all'
      },
      {
        name: '订单列表',
        index: '/order/all'
      }, {
        name: '订单详情',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getOrderDetaile()
    console.log("order detail", this.$route.query)
    
  },

  methods: {
    goBack() {
      console.log("Back")
      // this.$router.go(-1);
      let params = this.$store.state.OrderSeachForm || {}
      params.back = true;
      this.$store.commit('setOrderSeachForm', params)
      window.history.go(-1)
    },
    async getOrderDetaile() {
      const res = await this.$http.get('/nktdec/orderinfo/orderinfo/' + this.$route.params.id)
      console.log(res.data.result)
      this.orderData = res.data.result
      this.goodInfo = this.orderData.orderItems
    },
    getsum(params) {
      const { columns, data } = params
      // console.log(data)
      // console.log(columns)
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价'
          return
        }
        if (column.property === 'goodTeamPrice') {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' 元'
          }
        } else if (index === 5) {
          sums[index] = this.orderData.wholePrice + '元'
        }
      })
      return sums
    }
  },
  computed: {
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    }
  }
}
</script>
<style scoped>
.orderbox {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  width: 1000px;
  flex-wrap: wrap;
}

.infobox {
  width: 48%;
  height: 215px;
  /* background-color: pink; */
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
}

.infocopybox {
  width: 100%;
  height: 80px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
}

.payinfobox {
  flex-direction: column;
  margin-top: 30px;
}

.leftbox {
  /* background-image: linear-gradient(to bottom, rgb(241, 238, 238), #b7dcff); */
  border: 1px solid #ccc;
  margin-right: 30px;
}

.info {
  margin-left: 10px;
}

.rightbox {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.paybox {
  /* background-image: linear-gradient(to bottom, rgb(241, 238, 238), #c7f8c1); */
  border-radius: 10px;
  height: 215px;
  border: 1px solid #ccc;
}

.infocopybox .paybox {
  width: 48%;
}

.marginleft10 {
  margin-left: 10px;
}

.rowflex {
  display: flex;
  justify-content: space-between;
  /* flex-direction: column;   */
}
</style>
