<template>
    <div style="padding: 5px 10px 20px 10px;">
        <div class="page-header-tip" style="cursor: pointer;" @click="goBack">
            <i class="el-icon-back"></i> {{ backName }}
        </div>
        <div class="page-header-tip">|</div>
        <div class="page-header-tip" style="font-size:20px;">{{ titleName }}</div>
    </div>
</template>

<script>

export default {
    props: {
        title: {
            type: String,
            required: false,
            default: ""
        },
        backTitle: {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            titleName: "详细信息",
            backName: "返回"
        }
    },
    created() {
        if (this.backTitle) {
            this.backName = this.backTitle
        }
        if (this.title) {
            this.titleName = this.title
        }
    },
    methods: {
        goBack() {
            this.$emit("back", {})
        }
    }

}
</script>


<style scoped>
.float-left {
    float: left;
}

.page-header-tip {
    float: left;
    color: gray;
    height: 40px;
    line-height: 40px;
    margin-right: 20px;
}
</style>