<template>
  <div style="text-align: left; color:red;">{{ msg }}</div>
  <el-form :model="seachForm" :inline="true" style="margin-top: 20px; display: flex; justify-content: flex-start">
    <el-form-item label="订单编号">
      <el-input v-model="seachForm.orderCode" clearable size="small" placeholder="请输入订单编号！"></el-input>
    </el-form-item>
    <el-form-item label="订单类型">
      <el-select v-model="seachForm.orderType" clearable size="small" placeholder="请输入订单类型">
        <el-option v-for="(item, index) in orderTypeOption" :key="index" :label="item.label" :value="item.key">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="手机号">
      <el-input size="small" v-model="seachForm.cellphone" clearable placeholder="请输入手机号！"></el-input>
    </el-form-item>
    <el-form-item v-if="seachForm.orderType === 'GROUPORDER'">
      <el-select v-model="expotissue" allow-create filterable clearable placeholder="选择团购期数" size="small"
        style="margin-left:20px">
        <el-option v-for="item in nearOption" :key="item" :label="item" :value="item">
          <span style="float: left">{{ item }}</span>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="info" @click="clearall" size="mini" style="margin-left: 20px">清空</el-button>
      <el-button type="primary" @click="onSubmit" size="mini" style="margin-left: 20px">查询</el-button>
      <el-button type="success" @click="exportfile" size="mini" style="margin-left: 20px">导出</el-button>
      <!-- <a id="downloada" hidden download="myFileName">
      </a>-->
    </el-form-item>
  </el-form>
  <el-table :data="tableData" :row-key="rowKey">
    <el-table-column type="expand">
      <template #default="props">
        <el-form label-position="left" inline class="demo-table-expand"
          style="display: flex; flex-wrap: wrap; flex-direction: row">
          <el-form-item label="收货人" style="width: 45%">
            <span>{{ props.row.linkman }}</span>
          </el-form-item>
          <el-form-item label="团长电话" style="width: 45%" v-if="props.row.orderType === 'GROUPORDER'">
            <span>{{ props.row.managerPhone }}</span>
          </el-form-item>
          <el-form-item label="地址" style="width: 45%">
            <span>{{ props.row.address }}</span>
          </el-form-item>
        </el-form>
      </template>
    </el-table-column>
    <el-table-column label="订单编号" prop="orderCode"></el-table-column>
    <el-table-column label="订单类型" prop="orderTypeVal"></el-table-column>
    <el-table-column label="订单状态" prop="orderStateVal">
      <template #default="scope">
        <el-tag v-if="
          scope.row.orderState === 'DELIVERYED' ||
          scope.row.orderState === 'TODO'
        " type="warning">
          {{
              scope.row.orderState === "DELIVERYED"
                ? scope.row.orderStateVal
                : "待发货"
          }}
        </el-tag>
        <el-tag v-else-if="scope.row.orderState === 'CANCELLED'" type="info">
          {{
              scope.row.orderStateVal
          }}
        </el-tag>
        <el-tag v-else-if="scope.row.orderState === 'NEWCREATED'" type>
          {{
              scope.row.orderStateVal
          }}
        </el-tag>
        <el-tag v-else type="success">{{ scope.row.orderStateVal }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="联系电话" prop="cellphone"></el-table-column>

    <el-table-column label="创建时间" prop="createDate"></el-table-column>
    <el-table-column label="金额（元）" prop="totalMoney"></el-table-column>
    <el-table-column label="数量（件）" prop="totalNumber"></el-table-column>

    <el-table-column label="备注" prop="note"></el-table-column>

    <el-table-column label="操作">
      <template #default="scope">
        <!-- <div> -->
        <el-button type="text" v-if="
          scope.row.orderType !== 'CLASSORDER' &&
          scope.row.orderState === 'TODO'
        " @click="showDeliveryDialog(scope.row)">订单发货</el-button>
        <el-button type="text" @click="orderDetail(scope.row)" style="margin-right: 10px">订单详情</el-button>
        <!-- </div>
        <div>-->
        <el-button v-if="
          scope.row.orderState === 'TODO' ||
          scope.row.orderState === 'NEWCREATED'
        " style="margin-left: 0px !important" type="text" @click="deleteorder(scope.row)">取消订单</el-button>
        <!-- </div> -->
      </template>
    </el-table-column>
  </el-table>
  <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
    :page-sizes="[10, 20, 30]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px" :total="totalElement"></el-pagination>

  <el-dialog v-model="dialogVisible" :visible.sync="dialogVisible" v-if="dialogVisible" title="订单发货"
    @close="cancelModal">
    <el-form :model="form" :rules="rules">
      <el-form-item label="快递公司" prop="expressName" label-width="200px" style="width:400px;">
        <el-input v-model="form.expressName" autocomplete="off" />
      </el-form-item>
      <el-form-item label="快递单号" prop="expressCode" label-width="200px" style="width:400px;">
        <el-input v-model="form.expressCode" autocomplete="off" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancelModal">取消</el-button>
        <el-button type="primary" @click="ordersend">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { reactive, ref } from 'vue'
export default {
  data() {
    const form = reactive({
      expressName: null,
      expressCode: null
    })
    return {
      msg: '全部订单',
      seachForm: {
        orderCode: '',
        orderType: '',
        cellphone: ''
      },
      orderTypeOption: [],
      tableData: [],
      currentPage: 1,
      totalElement: 0,
      pageSize: 10,
      nearOption: [],
      expotissue: "",
      orderState: '',
      dialogVisible: false,
      form: form,
      currentOrder: null,
      rules: {
        expressName: [
          { required: true, message: '请输入快递公司！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        expressCode: [
          { required: true, message: '请输入快递单号！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      }

    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '订单管理',
        index: '/order/all'
      },
      {
        name: '订单列表',
        index: '/order/all'
      }]
    this.$store.commit('setBreadInfo', Arr)
    // this.getOrder(this.OrderSeachForm)
    console.log("created")
    this.recoverParams()
    this.onSearch()
    this.getapplyType()
    this.getnearInfo()
  },
  mounted() {
    this.$nextTick(() => {
    })
  },
  watch: {
    $route(to, from) {
      // console.log("watch.....", to, from)
      if (to.path !== from.path) {
        if (to.params.statu === 'all') {
          this.orderState = ''
          // this.getOrder(this.OrderSeachForm)
          this.msg = '全部订单'
          this.currentPage = 1;
          this.onSearch()
        } else if (to.params.statu === 'delivery') {
          this.orderState = 'TODO'
          // this.getOrder(this.OrderSeachForm)
          this.msg = '待发货'
          this.currentPage = 1;
          this.onSearch()
        } else if (to.params.statu === 'receipt') {
          this.orderState = 'DELIVERYED'
          // this.getOrder(this.OrderSeachForm)
          this.msg = '待收货'
          this.currentPage = 1;
          this.onSearch()
        } else if (to.params.statu === 'pay') {
          this.orderState = 'NEWCREATED'
          // this.getOrder(this.OrderSeachForm)
          this.msg = '待付款'
          this.currentPage = 1;
          this.onSearch()
        } else if (to.params.statu === 'complete') {
          this.orderState = 'DONE'
          // this.getOrder(this.OrderSeachForm)
          this.msg = '已完成'
          this.currentPage = 1;
          this.onSearch()
        } else if (to.params.statu === 'cancel') {
          this.orderState = 'CANCELLED'
          // this.getOrder(this.OrderSeachForm)
          this.msg = '已取消'
          this.currentPage = 1;
          this.onSearch()
        }

      }
    }
  },
  methods: {
    rowKey(row) {
      // console.log(row, "0000000000000")
      return row.id;
    },
    restoreParams() {
      console.log("restore params")
      let params = {};
      params.orderCode = this.seachForm.orderCode
      params.orderState = this.orderState
      params.orderType = this.seachForm.orderType
      params.cellphone = this.seachForm.cellphone
      params.expotissue = this.expotissue
      params.pageNo = this.currentPage
      params.pageSize = this.pageSize
      console.log(params)
      this.$store.commit('setOrderSeachForm', params)
    },
    recoverParams() {
      let params = this.$store.state.OrderSeachForm
      this.currentPage = params.pageNo || 1;
      this.pageSize = params.pageSize || 10;
      this.orderState = params.orderState || "";
      this.expotissue = params.expotissue || "";
      this.seachForm.orderCode = params.orderCode || "";
      this.seachForm.orderType = params.orderType || "";
      this.seachForm.cellphone = params.cellphone || "";
      console.log(params)
    },
    exportFactorySettings() {
      let url = this.getFactorySettingsUrl(false);
      console.log(url);
      this.$http.get(url, this.headerConfig).then(response => {
        console.log(response);
        if (response.data) {
          let csvData = "\uFEFF" + response.data;
          let fileName = "订单信息";
          let blob = new Blob([csvData], {
            type: "text/csv;charset=utf-8;"
          });
          if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, fileName + '.csv');
          } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a");
            let csvUrl = URL.createObjectURL(blob);
            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = fileName + '.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        } else {

        }
      })
    },
    async exportfile() {
      console.log("export file")
      if (!this.expotissue && this.seachForm.orderCode && !this.seachForm.orderType && !this.seachForm.cellphone) {
        this.$message.error("请选择查询条件")
        return;
      }
      let exportUrl = '/nktdec/orderinfo/orderinfos?issue=' + this.expotissue || "";
      exportUrl += "&orderCode=" + this.seachForm.orderCode || "";
      exportUrl += "&orderType=" + this.seachForm.orderType || "";
      exportUrl += "&cellphone=" + this.seachForm.cellphone || "";
      exportUrl += "&orderState=" + this.orderState || "";

      var heads = {}
      heads["Content-Disposition"] = 'attachment;filename="order.csv"'
      const res = await this.$http({
        method: 'get',
        headers: heads,
        url: exportUrl
      })
      console.log(res)
      let blob = new Blob(['\ufeff' + res.data], {
        type: "text/csv;charset=utf-8;"
      });
      let link = document.createElement("a");
      let csvUrl = URL.createObjectURL(blob);
      link.href = csvUrl;
      link.style = "visibility:hidden";
      // link.download = '第' + this.expotissue + '期团购订单.csv';
      link.download = "订单信息";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      //  var ele = document.querySelector('#downloada')
      // // console.log(this.$http.defaults.baseURL)
      // ele.href=this.$http.defaults.baseURL + '/nktdec/orderinfo/orderinfos?issue='+this.expotissue
      // let fileUrl = window.URL.createObjectURL(this.$http.defaults.baseURL + '/nktdec/orderinfo/orderinfos?issue='+this.expotissue)
      // ele.download('filename',fileUrl) // 方法二使用到的a标签download方式。
      // window.URL.revokeObjectURL(fileUrl)

      // ele.click()
    },
    async getnearInfo() {
      const res = await this.$http.get('/nktdec/salesactivity/salesissues')
      console.log('=================', res.data)
      this.nearOption = res.data
      // this.expotissue = res.data[0] || ''
    },
    onSubmit() {
      this.currentPage = 1
      this.pageNo = 0;
      this.pageSize = 10;
      this.onSearch()
    },
    async onSearch() {
      console.log(this.seachForm)
      // let str = this.query(this.orderState, this.seachForm);
      // var str = '?' + (this.seachForm.orderCode.trim() === '' ? '' : 'orderCode=' + this.seachForm.orderCode) + (this.seachForm.orderType.trim() === '' ? '' : (this.seachForm.orderCode.trim() === '' ? '' : '&') + 'orderType=' + this.seachForm.orderType) + (this.seachForm.cellphone.trim() === '' ? '' : (this.seachForm.orderCode.trim() === '' && this.seachForm.orderType.trim() === '' ? '' : '&') + 'cellphone=' + this.seachForm.cellphone)
      let str = "?orderState=" + this.orderState || "";
      str += "&orderCode=" + this.seachForm.orderCode || "";
      str += "&orderType=" + this.seachForm.orderType || "";
      str += "&cellphone=" + this.seachForm.cellphone || "";
      str += "&expotissue=" + this.expotissue || "";
      if (this.pageSize) {
        str += "&pageSize=" + this.pageSize;
      }

      console.log(this.currentPage, "....................")
      const res = await this.$http.get('/nktdec/orderinfo/orderinfos/' + this.currentPage + str)
      console.log(res.data)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = res.data.number + 1;
      // this.msg = '全部订单'


      // this.$store.commit('setOrderSeachForm', { ...this.seachForm, currentpage: '1', pageSize: '20' })
      this.restoreParams()

    },
    async clearall() {
      const res = await this.$http.get('/nktdec/orderinfo/orderinfos/1')
      console.log(res.data)
      this.seachForm.orderCode = ''
      this.seachForm.orderType = ''
      this.seachForm.cellphone = ''
      this.expotissue = "";
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.msg = '全部订单'
      this.currentPage = 1
      // this.$store.commit('setOrderSeachForm', { ...this.seachForm, currentpage: '1', pageSize: '20' })
      this.restoreParams()
    },
    async getapplyType() {
      const res = await this.$http.get('/nktdec/orderinfo/ordertypes')
      console.log(res.data)
      res.data.forEach(item => {
        var key = Object.keys(item)
        var label = Object.values(item)
        this.orderTypeOption.push({
          key: key[0],
          label: label[0]
        })
      })
    },
    async handleSizeChange(val) {
      console.log(val)
      // const res = await this.$http.get('/nktdec/orderinfo/orderinfos/1' + '?type=GROUP&pageSize=' + val + (this.seachForm.orderCode.trim() === '' ? '' : '&orderCode=' + this.seachForm.orderCode) + (this.seachForm.orderType.trim() === '' ? '' : '&orderType=' + this.seachForm.orderType) + (this.seachForm.cellphone.trim() === '' ? '' : '&cellphone=' + this.seachForm.cellphone))
      // this.tableData = res.data.content
      console.log(res)
      // this.totalElement = res.data.totalElements
      // this.pageSize = res.data.size
      this.currentPage = 1
      this.pageSize = val;
      this.onSearch()
      //  this.restoreParams()
      // this.$store.commit('setOrderSeachForm', { ...this.OrderSeachForm, currentpage: '1', pageSize: val })
    },
    async handleCurrentChange(val) {
      console.log("handleCurrentChange", val)
      // var str = val + '?&pageSize=' + this.pageSize + (this.seachForm.orderCode.trim() === '' ? '' : '&orderCode=' + this.seachForm.orderCode) + (this.seachForm.orderType.trim() === '' ? '' : '&orderType=' + this.seachForm.orderType) + (this.seachForm.cellphone.trim() === '' ? '' : '&cellphone=' + this.seachForm.cellphone)
      // const res = await this.$http.get('/nktdec/orderinfo/orderinfos/' + str)
      // this.tableData = res.data.content
      // console.log(res)
      // this.totalElement = res.data.totalElements
      // this.pageSize = res.data.size
      let params = this.$store.state.OrderSeachForm || {}
      if (!params.back) {
        this.currentPage = val
        this.onSearch()
      }

      //  this.restoreParams()
      // this.$store.commit('setOrderSeachForm', { ...this.OrderSeachForm, currentpage: val, pageSize: this.pageSize })
    },
    orderDetail(row) {
      console.log('前往详情页', row)
      // this.restoreParams()
      this.$router.push('/orderdetaile/' + row.id + "?p=" + this.currentPage)
    },
    showDeliveryDialog(item) {
      console.log(item);
      this.dialogVisible = true;
      this.currentOrder = item;
    },
    cancelModal() {
      this.dialogVisible = false;
      console.log("cancel modal")
      this.form.expressCode = null;
      this.form.expressName = null;
    },
    async ordersend() {
      console.log("发货", this.form)
      if (!this.form.expressName || !this.form.expressCode) {
        return;
      }
      var params = {};
      params.expressName = this.form.expressName;
      params.expressCode = this.form.expressCode;
      const res = await this.$http.put('/nktdec/orderinfo/deliveryorder/' + this.currentOrder.id, params);
      console.log(res)
      this.dialogVisible = false;

      if (res.data.code === '1') {
        this.$message.success('发货成功！')
      }
    },
    deleteorder(row) {
      console.log('取消订单', row)

      this.$prompt('此操作取消该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入订单取消原因',
        inputErrorMessage: '取消原因不能为空或者不超过200个字符',
        inputValue: null,
        inputValidator: (e) => {
          if (!e || e.length > 200) {
            return false;
          }

        },
        type: 'warning'
      }).then(async (e) => {
        var params = {}
        params.remark = e.value;
        const res = await this.$http.put('/nktdec/orderinfo/cancelorder/' + row.id, params)
        console.log(res)
        if (res.data.code === '1') {
          this.$message.success('取消成功！')
          // this.getOrder(this.OrderSeachForm)
          this.onSearch()
        }
      })
    },
    query(str, obj) {
      if (obj.currentpage) {
        str += obj.currentpage + (this.orderState ? this.orderState : '?')
        for (let i in obj) {
          if (i === 'currentpage') {
            continue
          } else {
            if (obj[i] !== '') {
              str += '&' + i + '=' + obj[i]
            }
          }
        }
      } else {
        str += '1' + (this.orderState ? this.orderState : '?')
      }
      return str
    },
    // async getOrder(obj) {
    //   var str = this.query('', obj)
    //   const res = await this.$http.get('/nktdec/orderinfo/orderinfos/' + str)
    //   if (obj.orderCode) {
    //     this.seachForm.orderCode = obj.orderCode
    //   }
    //   if (obj.orderType) {
    //     this.seachForm.orderType = obj.orderType
    //   }
    //   if (obj.cellphone) {
    //     this.seachForm.cellphone = obj.cellphone
    //   }
    //   console.log(res.data.content)
    //   this.tableData = res.data.content
    //   this.totalElement = res.data.totalElements
    //   this.pageSize = res.data.size
    //   this.currentPage = obj.currentpage - 0
    //   this.$store.commit('setOrderSeachForm', { ...this.OrderSeachForm, currentpage: obj.currentpage, pageSize: '20' })
    // }
  },
  computed: {
    OrderSeachForm() {
      console.log("OrderSeachForm")

      return this.$store.state.OrderSeachForm
    }
  }
}
</script>
<style scoped>
</style>
